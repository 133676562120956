<template>
  <div
    v-if="noWrap && show"
    :class="computedClasses"
    :style="{ zIndex: zIndex }"
    @click="emit('click', $event)"
  >
    <div class="zoverlay-bg" />
    <div :class="contentClasses">
      <slot name="overlay">
        <ZSpinner />
        <div
          v-if="message"
          class="mt-3"
        >
          {{ message }}
        </div>
      </slot>
    </div>
  </div>

  <div
    v-else-if="!noWrap"
    class="zoverlay-wrapper"
  >
    <!-- wrapped content -->
    <slot />

    <!-- overlay content -->
    <ClientOnly>
      <div
        v-if="show"
        :class="computedClasses"
        :style="{ zIndex: zIndex }"
      >
        <div class="zoverlay-bg" />
        <div :class="contentClasses">
          <slot name="overlay">
            <ZSpinner />
            <div
              v-if="message"
              class="mt-3"
            >
              {{ message }}
            </div>
          </slot>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import type { OverlayVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  show?: boolean
  noWrap?: boolean
  fixed?: boolean
  noCenter?: boolean
  zIndex?: string
  variant?: OverlayVariant
  opaque?: boolean
  message?: string
}>(), {
  zIndex: '10',
  variant: 'light',
})

const emit = defineEmits<{
  click: [event: Event]
}>()

const computedClasses = computed(() => [
  'zoverlay',
  `zoverlay-${props.variant}`,
  {
    'zoverlay-fixed': props.fixed,
    'zoverlay-opaque': props.opaque,
  },
])

const contentClasses = computed(() => [
  'zoverlay-content',
  {
    'zoverlay-content-center': !props.noCenter,
  },
])
</script>
